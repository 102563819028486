// extracted by mini-css-extract-plugin
export var dateHolder = "postCard-module--dateHolder--u4JXf";
export var postCard = "postCard-module--postCard--+PorL";
export var postCardImg = "postCard-module--postCardImg--aKHzI";
export var rsBadgeBlue = "postCard-module--rs-badge-blue--JlzDd";
export var rsBadgeContent = "postCard-module--rs-badge-content--qKEEC";
export var rsBadgeCyan = "postCard-module--rs-badge-cyan--eMv-n";
export var rsBadgeGreen = "postCard-module--rs-badge-green--8WLa6";
export var rsBadgeIndependent = "postCard-module--rs-badge-independent--wuxFA";
export var rsBadgeOrange = "postCard-module--rs-badge-orange--2TAYz";
export var rsBadgeRed = "postCard-module--rs-badge-red--Mylx9";
export var rsBadgeViolet = "postCard-module--rs-badge-violet--BaNsF";
export var rsBadgeYellow = "postCard-module--rs-badge-yellow--SOxRF";
export var rsBtnBlue = "postCard-module--rs-btn-blue--nQknv";
export var rsBtnCyan = "postCard-module--rs-btn-cyan--sniXS";
export var rsBtnGreen = "postCard-module--rs-btn-green---X7Wc";
export var rsBtnOrange = "postCard-module--rs-btn-orange--hadVI";
export var rsBtnRed = "postCard-module--rs-btn-red--mQyAN";
export var rsBtnViolet = "postCard-module--rs-btn-violet--GnNqc";
export var rsBtnYellow = "postCard-module--rs-btn-yellow--6Vucd";
export var rsFlexBoxGridItem1 = "postCard-module--rs-flex-box-grid-item-1--H86Ku";
export var rsFlexBoxGridItem10 = "postCard-module--rs-flex-box-grid-item-10--VmV8Y";
export var rsFlexBoxGridItem11 = "postCard-module--rs-flex-box-grid-item-11--fjbN0";
export var rsFlexBoxGridItem12 = "postCard-module--rs-flex-box-grid-item-12--cZ+eC";
export var rsFlexBoxGridItem13 = "postCard-module--rs-flex-box-grid-item-13--1tN+4";
export var rsFlexBoxGridItem14 = "postCard-module--rs-flex-box-grid-item-14--iG6Tj";
export var rsFlexBoxGridItem15 = "postCard-module--rs-flex-box-grid-item-15--XBMu2";
export var rsFlexBoxGridItem16 = "postCard-module--rs-flex-box-grid-item-16--zNo5V";
export var rsFlexBoxGridItem17 = "postCard-module--rs-flex-box-grid-item-17--MCc+K";
export var rsFlexBoxGridItem18 = "postCard-module--rs-flex-box-grid-item-18--ROU-A";
export var rsFlexBoxGridItem19 = "postCard-module--rs-flex-box-grid-item-19--rorCP";
export var rsFlexBoxGridItem2 = "postCard-module--rs-flex-box-grid-item-2--f4wFx";
export var rsFlexBoxGridItem20 = "postCard-module--rs-flex-box-grid-item-20--UEuzA";
export var rsFlexBoxGridItem21 = "postCard-module--rs-flex-box-grid-item-21--OhthO";
export var rsFlexBoxGridItem22 = "postCard-module--rs-flex-box-grid-item-22---Bb7s";
export var rsFlexBoxGridItem23 = "postCard-module--rs-flex-box-grid-item-23--MGEp5";
export var rsFlexBoxGridItem24 = "postCard-module--rs-flex-box-grid-item-24--tf6ax";
export var rsFlexBoxGridItem3 = "postCard-module--rs-flex-box-grid-item-3--TQaaF";
export var rsFlexBoxGridItem4 = "postCard-module--rs-flex-box-grid-item-4--F4131";
export var rsFlexBoxGridItem5 = "postCard-module--rs-flex-box-grid-item-5--6f2kE";
export var rsFlexBoxGridItem6 = "postCard-module--rs-flex-box-grid-item-6--yZFA0";
export var rsFlexBoxGridItem7 = "postCard-module--rs-flex-box-grid-item-7--ayIn9";
export var rsFlexBoxGridItem8 = "postCard-module--rs-flex-box-grid-item-8--y35WO";
export var rsFlexBoxGridItem9 = "postCard-module--rs-flex-box-grid-item-9--yATMS";
export var rsFlexBoxGridItemOrder1 = "postCard-module--rs-flex-box-grid-item-order-1--c2eiK";
export var rsFlexBoxGridItemOrder10 = "postCard-module--rs-flex-box-grid-item-order-10--FhnEA";
export var rsFlexBoxGridItemOrder11 = "postCard-module--rs-flex-box-grid-item-order-11--GObyc";
export var rsFlexBoxGridItemOrder12 = "postCard-module--rs-flex-box-grid-item-order-12--CGOMp";
export var rsFlexBoxGridItemOrder13 = "postCard-module--rs-flex-box-grid-item-order-13--n3ucL";
export var rsFlexBoxGridItemOrder14 = "postCard-module--rs-flex-box-grid-item-order-14--tPPHi";
export var rsFlexBoxGridItemOrder15 = "postCard-module--rs-flex-box-grid-item-order-15--IJc5M";
export var rsFlexBoxGridItemOrder16 = "postCard-module--rs-flex-box-grid-item-order-16--L87SI";
export var rsFlexBoxGridItemOrder17 = "postCard-module--rs-flex-box-grid-item-order-17--q-WhM";
export var rsFlexBoxGridItemOrder18 = "postCard-module--rs-flex-box-grid-item-order-18--X9AdH";
export var rsFlexBoxGridItemOrder19 = "postCard-module--rs-flex-box-grid-item-order-19--TjAGZ";
export var rsFlexBoxGridItemOrder2 = "postCard-module--rs-flex-box-grid-item-order-2--sBrz2";
export var rsFlexBoxGridItemOrder20 = "postCard-module--rs-flex-box-grid-item-order-20--ewLJ3";
export var rsFlexBoxGridItemOrder21 = "postCard-module--rs-flex-box-grid-item-order-21--fMzdl";
export var rsFlexBoxGridItemOrder22 = "postCard-module--rs-flex-box-grid-item-order-22--1BMSN";
export var rsFlexBoxGridItemOrder23 = "postCard-module--rs-flex-box-grid-item-order-23--H6Xjn";
export var rsFlexBoxGridItemOrder24 = "postCard-module--rs-flex-box-grid-item-order-24--5lEs8";
export var rsFlexBoxGridItemOrder3 = "postCard-module--rs-flex-box-grid-item-order-3--JTI+g";
export var rsFlexBoxGridItemOrder4 = "postCard-module--rs-flex-box-grid-item-order-4--MuNtr";
export var rsFlexBoxGridItemOrder5 = "postCard-module--rs-flex-box-grid-item-order-5--TNcXt";
export var rsFlexBoxGridItemOrder6 = "postCard-module--rs-flex-box-grid-item-order-6--t95fS";
export var rsFlexBoxGridItemOrder7 = "postCard-module--rs-flex-box-grid-item-order-7--7vfxq";
export var rsFlexBoxGridItemOrder8 = "postCard-module--rs-flex-box-grid-item-order-8--Snmnl";
export var rsFlexBoxGridItemOrder9 = "postCard-module--rs-flex-box-grid-item-order-9--4WVJ0";
export var rsTagBlue = "postCard-module--rs-tag-blue--kS4yw";
export var rsTagCyan = "postCard-module--rs-tag-cyan--8kLCv";
export var rsTagGreen = "postCard-module--rs-tag-green--wF0oO";
export var rsTagOrange = "postCard-module--rs-tag-orange--j2a7C";
export var rsTagRed = "postCard-module--rs-tag-red--cJvDk";
export var rsTagViolet = "postCard-module--rs-tag-violet--BgJOY";
export var rsTagYellow = "postCard-module--rs-tag-yellow--sXA+c";
export var rsThemeDark = "postCard-module--rs-theme-dark--AHrCA";
export var rsThemeHighContrast = "postCard-module--rs-theme-high-contrast--tBp1R";
export var title = "postCard-module--title--YyFMa";